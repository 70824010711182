import { PrintStatusValues } from '@/ConstValues';

export function IsStatusLessThanAnotherStatus(
  status: PrintStatusValues,
  secondStatus: PrintStatusValues
): boolean {
  return status < secondStatus;
}
export function IsStatusGreaterThanOrEqualToAnotherStatus(
  status: PrintStatusValues,
  secondStatus: PrintStatusValues
): boolean {
  return status >= secondStatus;
}
