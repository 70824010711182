export const StringHelper = {
  getColorText(color: number): string {
    switch (color) {
      case 1:
        return 'Black Only';
      case 4:
        return 'Full Color';
      case 5:
        return 'Full Color + White';
      case 6:
        return 'Full Color + 2 Hit White';
    }
    return '';
  },
  getStatusTextBackgroundColorClass(status: string) {
    switch (status) {
      case 'Pending':
        return 'j1l-purple-background';
      case 'Production hold':
      case 'Processing Error(s)':
      case 'Waiting on Codes':
        return 'coral-background';
      case 'Starting soon':
        return 'sky-blue-background';
      case 'Sent to press':
      case 'Labels printed':
      case 'Labels cut':
      case 'Labels ready':
      case 'Packaged!':
        return 'lizard-green-background';
      case 'Shipped!':
      case 'Pickup Ready!':
        return 'j1l-brown-background';
      case 'Delivered':
      case 'Picked up':
        return 'j1l-black-background';
    }
    return 'j1l-black-background';
  },
  getStatusTextColorClass(status: string) {
    switch (status) {
      case 'Pending':
        return 'j1l-purple';
      case 'Production hold':
      case 'Processing Error(s)':
      case 'Waiting on Codes':
        return 'coral';
      case 'Starting soon':
      case 'Codes processed':
        return 'sky-blue';
      case 'Sent to press':
      case 'Labels printed':
      case 'Labels cut':
      case 'Labels ready':
      case 'Packaged!':
        return 'lizard-green';
      case 'Shipped!':
      case 'Pickup Ready!':
        return 'j1l-brown';
      case 'Delivered':
      case 'Picked up':
        return 'j1l-black';
      case 'Processing Codes':
        return 'j1l-processing';
    }
    return 'j1l-black';
  },
  getStatusTextBorderColorClass(status: string) {
    switch (status) {
      case 'Pending':
        return 'j1l-purple-border';
      case 'Production hold':
      case 'Processing Error(s)':
      case 'Waiting on Codes':
        return 'coral-border';
      case 'Starting soon':
        return 'sky-blue-border';
      case 'Sent to press':
      case 'Labels printed':
      case 'Labels cut':
      case 'Labels ready':
      case 'Packaged!':
        return 'lizard-green-border';
      case 'Shipped!':
      case 'Pickup Ready!':
        return 'j1l-brown-border';
      case 'Delivered':
      case 'Picked up':
        return 'j1l-black-border';
    }
    return 'j1l-black-border';
  },
  GetOrderStatusForInvoicing(invoices: any = null) {
    let result: any[] = [];
    if (invoices[0]) {
      invoices.forEach((invoice: any, i: number) => {
        let invoiceInfo = {
          header: invoice.PackingList.WasPickedUp
            ? 'Picked up ' + invoice.PackingList.DateCompleted
            : invoice.PackingList.IsCompleted
            ? 'Delivered ' + invoice.PackingList.DateCompleted
            : 'Shipped On ' + invoice.PackingList.DateShipped,
          subheader: invoice.PackingList.IsCompleted
            ? 'Your package was delivered'
            : 'Your package is in transit.',
          description: invoice.PackingList.IsCompleted
            ? 'Please look in your mailbox or wherever you typically get mail.'
            : 'Your package is in transit.'
        };
        result.push(invoiceInfo);
      });
      return result;
    }
    return result;
  },
  doesFirstStringContainSecond(firstItem: string, secondItem: string) {
    if (!firstItem) return false;
    if (!secondItem) return true;
    let doesMatch = firstItem
      .toLowerCase()
      .trim()
      .includes(secondItem.toLowerCase().trim());
    return doesMatch;
  },
  formatQuantity(quantity: number | null) {
    if (isNaN(quantity as number)) return null;
    return quantity != null
      ? quantity.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      : null;
  },
  displayAsMoney(value: number) {
    return value ? value.toFixed(2) : '0.00';
  }
};

export function isValidCheckDigit(s: string) {
  if (!s) return;
  var result = 0;
  var removeCheckDigit = s.substring(0, s.length - 1);
  var rs = reverseString(removeCheckDigit);
  for (let counter = 0; counter < rs.length; counter++) {
    result = result + parseInt(rs.charAt(counter)) * Math.pow(3, (counter + 1) % 2);
  }
  var returnValue = (10 - (result % 10)) % 10,
    checkdigit = Number(s[s.length - 1]);
  var isValidCheckDigit = returnValue == checkdigit;
  return isValidCheckDigit;
}

export function getExpectedCheckDigit(s: string) {
  if (!s) return;
  var result = 0;
  var removeCheckDigit = s.substring(0, s.length - 1);
  var rs = reverseString(removeCheckDigit);
  for (let counter = 0; counter < rs.length; counter++) {
    result = result + parseInt(rs.charAt(counter)) * Math.pow(3, (counter + 1) % 2);
  }
  var returnValue = (10 - (result % 10)) % 10;
  return returnValue;
}

export function reverseString(s: string) {
  let splitext = s.split('');
  let revertext = splitext.reverse();
  let reversed = revertext.join('');
  return reversed;
}

export function removeSpecialCharacters(str: string) {
  return str.replace(/[^\w\s.-]/gi, '');
}
