export const GlobalFilters = {
  addCommas(value: number): string {
    if (value) return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    return '';
  },
  formatStringLength(value: string, length: number) {
    if (value) {
      return `${value.substring(0, length)}${value.length >= length ? '...' : ''}`;
    }
    return '';
  },
  formatPhoneNumber(phoneNumber: string) {
    var cleaned = ('' + phoneNumber).replace(/\D/g, '');
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return null;
  },
  formatNumberToDollarStringValue(value: number): string {
    if (value) {
      let totalcost = value;
      return totalcost.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD'
      });
    }
    return '';
  },
  formatNumberWithCommas(value: number): string {
    if (value) {
      let number = value;
      return number.toLocaleString('en-US');
    }
    return '';
  }
};
