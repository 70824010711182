import { ConfirmationDialogInfo } from '@/store/models/CustomerModel';
import store from '@/store/modules/index';

export function Confirm(
  action: any,
  confirmationTitle: string,
  confirmationText: string,
  cancelButtonText: string = 'Cancel',
  commitButtonText: string = 'I am sure',
  shouldPersist: boolean = false
) {
  const newConfirmationDialog = new ConfirmationDialogInfo({
    action,
    confirmationTitle,
    confirmationText,
    cancelButtonText,
    commitButtonText,
    shouldPersist
  });
  store.commit('profile/setConfirmationAction', newConfirmationDialog);
  return;
}
