import { Snackbar } from '@/store/models/CustomerModel';
import store from '@/store/modules/index';

export function SetSnackBar(
  message: string,
  actionText: string = 'Close',
  customAction: any = null,
  timeout: number = 5000,
  backgroundColor: string = '#333333',
  actionTextColor: string = 'pink'
) {
  store.dispatch(
    'customer/setSnackBarData',
    new Snackbar({
      message,
      actionText,
      customAction,
      timeout,
      backgroundColor,
      actionTextColor
    })
  );
}
