import { Orderline, OrderlineDetails } from '@/store/models/OrderlineModel';
import { AlertModel } from '@/store/models/DialogModel';
import { ConstValues } from '@/ConstValues';

export function getObjectDiff(obj1: any, obj2: any) {
  if (!obj1) return Object.keys(obj2);
  const diff = Object.keys(obj1).reduce((result, key) => {
    if (!obj2.hasOwnProperty(key)) {
      result.push(key);
    } else if (
      obj1[key] === obj2[key] ||
      (typeof obj1[key] === 'object' &&
        obj1[key] !== null &&
        !isObjectDiff(obj1[key], obj2[key]))
    ) {
      const resultKeyIndex = result.indexOf(key);
      result.splice(resultKeyIndex, 1);
    }
    return result;
  }, Object.keys(obj2));

  return diff;
}

export function areListsDifferent(list1: any, list2: any) {
  if (list1.length !== list2.length || list1.length === 0) return true;
  for (let i = 0; i < list2.length; i++) {
    let diff = getObjectDiff(list1[i], list2[i]);
    if (diff[0]) {
      return true;
    }
  }
  return false;
}

export function CloneWithoutReference(objectToClone: Object) {
  return JSON.parse(JSON.stringify(objectToClone));
}

export function getDistinctOccurences(list: any) {
  let counts: any = [];
  list.forEach((el: any) => (counts[el] = 1 + (counts[el] || 0)));
  return Object.keys(counts).length;
}

function isObjectDiff(obj1: any, obj2: any) {
  const diff = Object.keys(obj1).reduce((result, key) => {
    if (!obj2.hasOwnProperty(key)) {
      result.push(key);
    } else if (
      obj1[key] === obj2[key] ||
      (typeof obj1[key] === 'object' &&
        obj1[key] !== null &&
        !isObjectDiff(obj1[key], obj2[key]))
    ) {
      const resultKeyIndex = result.indexOf(key);
      result.splice(resultKeyIndex, 1);
    }
    return result;
  }, Object.keys(obj2));

  return diff.length > 0;
}

export function GetDisplayDate(orderline: Orderline) {
  switch (orderline.Status) {
    case ConstValues.PRINT_CLOSED:
    case ConstValues.PRINT_PAIDINTRANSIT:
    case ConstValues.PRINT_INVOICECREATED:
    case ConstValues.PRINT_READYTOINVOICE:
    case ConstValues.PRINT_WAITINGONPAYMENT:
    case ConstValues.PRINT_SHIPPED:
      if (orderline.OrderStatus.OrderStatusDisplay === 'Pickup Ready!') {
        return orderline.DatePackaged || orderline.DueDate;
      }
      return orderline.ShipDate || orderline.DueDate;
    case ConstValues.PRINT_SHIPPING:
      if (orderline.IsARush) {
        return orderline.EstimatedDueDate;
      }
      return orderline.DatePackaged || orderline.DueDate;
    default:
      return IsBadDate(new Date(orderline.EstimatedDueDate))
        ? orderline.DueDate
        : orderline.EstimatedDueDate;
  }
}

function IsBadDate(date: Date) {
  return !date || date.getFullYear() === 2001;
}

export function isOrderlineDetails(orderline: Orderline | OrderlineDetails) {
  return (orderline as OrderlineDetails).OD !== undefined;
}

export function SetIdsForSVGElements(svgList: SVGSVGElement[], isSpecial: boolean) {
  if (svgList) {
    let rectList: Element[];
    if (isSpecial) {
      let pathList = svgList.map((s: SVGSVGElement) => s.children[1]);

      //  let pathList = [...svgList[0].children[1].children];

      // pathList = pathList.sort((a: Element, b: Element) => {
      //   return (
      //     b.getBoundingClientRect().width - a.getBoundingClientRect().width
      //   );
      // });

      let idList = ['trimLineId', 'safetyLineId'];
      for (let i = 0; i < pathList.length; i++) {
        pathList[i].setAttribute('id', idList[i]);
      }
    } else {
      rectList = svgList.map((s: SVGSVGElement) => s.children[0].children[0]);
      console.log(rectList);
      rectList = rectList.filter(r => r !== undefined);
      let idList = ['trimLineId', 'safetyLineId'];
      for (let i = 0; i < rectList.length; i++) {
        rectList[i].id = idList[i];
      }
    }
  }
}

export function getItemStatusTypeByStatus(status: string) {
  if (status.toLowerCase().includes('proof')) {
    return 'OnHold.svg';
  } else if (status.toLowerCase().includes('hold')) {
    return 'OnHold.svg';
  } else if (status.toLowerCase().includes('temporary')) {
    return 'Tempitem.svg';
  } else {
    return 'Approved.svg';
  }
}

export function BuildItemAlertMessage(
  type: string,
  item: any,
  additionalData: any
): AlertModel {
  let returnValue;
  switch (type) {
    case 'material':
      returnValue = new AlertModel({
        Type: 'material',
        Title: ' Metallic/Clear has been selected.',
        Message: `Please note: You’ve selected Metallic/Clear! This option requires an advanced knowledge of graphic design applications in order to properly call WHITE INK on press. Files improperly setup could
        result in misprinted labels. If you are unsure whether your file is set up correctly with WHITE INK please contact us at info@just1label.com.`,
        SecondaryMessage: '',
        ConfirmationMessage: 'Change',
        IsCancelBtnDisabled: true,
        List: additionalData,
        ItemId: item.Id
      });
      return returnValue;
    case 'delete':
      returnValue = new AlertModel({
        Type: 'delete',
        Title: 'Delete this Item',
        Message: `This will DELETE '${item.PartNumber}' from the system. If there are any open orders for this item that HAVE NOT printed this item will be removed from them and your order price may be adjusted.`,
        SecondaryMessage: 'Are you sure you want to delete it?',
        ConfirmationMessage: 'Delete',
        IsCancelBtnDisabled: false,
        List: additionalData,
        ItemId: item.Id
      });
      return returnValue;
    case 'hold':
      returnValue = new AlertModel({
        Type: 'hold',
        Title: 'Place an item on Hold',
        Message:
          'NOTE: This will place your item on hold immediately. Any order lines that HAVE NOT been printed will also be placed on production hold until this item is approved.',
        SecondaryMessage:
          'The changes you are about to perform may affect the price(s) of the following orders:',
        ConfirmationMessage: 'Put On Hold',
        IsCancelBtnDisabled: false,
        List: additionalData,
        ItemId: item.Id
      });
      return returnValue;
    case 'orderProof':
      returnValue = new AlertModel({
        Type: 'proof',
        Title: 'This item is already on order',
        Message: 'Do you want to continue and order this proof?',
        ConfirmationMessage: 'Order Proof',
        IsCancelBtnDisabled: false,
        List: additionalData,
        ItemId: item.Id
      });
      return returnValue;
    case 'rollDirection':
      returnValue = new AlertModel({
        Type: 'rolldirection',
        Title: 'Choose default Unwind Position',
        Message:
          'This address currently has no default UNWIND POSITION selected. Please select from the options below. The most common unwind position for high speed packaging is a #4 Unwind (left side of the label dispenses first). If you are not sure please contact your manufacturer or product packaging representative for their equipment specifications.',
        ConfirmationMessage: 'Confirm',
        IsCancelBtnDisabled: false,
        List: additionalData,
        ItemId: item ? item.Id : null
      });
      return returnValue;
    default:
      return new AlertModel();
  }
}
export function GetUniqueElementsById(unfilteredArray: any[]) {
  const uniq = new Set(unfilteredArray.map(e => JSON.stringify(e)));
  let res = Array.from(uniq).map(e => JSON.parse(e));
  let data: any[] = [];
  for (let value of res) {
    if (!value.Id || !data.some(v => v.Id === value.Id)) {
      data.push(value);
    }
  }
  return data;
}
export function SortByDate(items: any[], dateProperty: string) {
  let nonOrderlines = [];
  let data = items;
  for (let item of data) {
    if (!item.OrderCreationDate) {
      nonOrderlines.push(item);
    }
  }
  data = data.filter(ol => ol.OrderCreationDate);
  let sortedArray = data.sort(
    (a, b) => new Date(b[dateProperty]).valueOf() - new Date(a[dateProperty]).valueOf()
  );
  data = [...nonOrderlines, ...sortedArray];
  return data;
}

export function Clone(entity: any) {
  // @ts-ignore
  return structuredClone(entity);
}
export function SortArrayByProperty(list: any[], property: string) {
  if (typeof list[0][property] === 'string') {
    return list.sort((a, b) => a[property].localeCompare(b[property]));
  }
  return list.sort((a, b) => a[property] - b[property]);
}
