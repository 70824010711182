import { Item } from '@/store/models/ItemModel';
import { RootState } from '@/store/models/RootState';
import { Store } from 'vuex';
import { Orderline } from '@/store/models/OrderlineModel';
import { Invoice } from '@/store/models/InvoiceModel';
import { orderlines } from '@/store/modules/orderlines';

export function ReplaceItemInStoreAndRefresh(
  store: Store<RootState>,
  items: Item[],
  itemToReplace: Item,
  shouldHandleFilteredItem: boolean = false,
  oldItemId: string = ''
) {
  if (oldItemId && !itemToReplace.Id) {
    let itemToRefresh = items.findIndex(i => i.Id === oldItemId);
    if (itemToRefresh !== -1) {
      items.splice(itemToRefresh, 1);
    }
  }
  let itemToRefresh = items.findIndex(i => i.Id === itemToReplace.Id);
  if (itemToRefresh !== -1) {
    items.splice(itemToRefresh, 1);
  } else {
    store.commit('items/setPartNumbers', [
      itemToReplace,
      ...store.getters['items/getItemPartNumber']
    ]);
  }
  if (itemToReplace.Id) {
    items.unshift(itemToReplace);
  }
  const newPayload = [...items];
  store.commit('items/itemsLoaded', newPayload);
  store.dispatch('orderlines/RefreshItemInOrderlines', itemToReplace);
  if (shouldHandleFilteredItem) {
    let filteredItems = store.getters['items/filteredItem'].map(
      (fi: any) => new Item(fi)
    );
    let targetindex = filteredItems.findIndex(
      (item: Item) => item.Id === itemToReplace.Id
    );
    targetindex > -1
      ? filteredItems.splice(targetindex, 1)
      : filteredItems.unshift(itemToReplace);
    const newPayload = [...filteredItems];
    store.commit('items/setFilteredItem', newPayload);
  }
}
export function HandleFilteredItemsActions(
  store: Store<RootState>,
  filteredItems: Item[],
  itemId: string,
  removeItem: boolean = false
) {
  if (removeItem) {
    let targetedIndex = filteredItems.findIndex(i => i.Id === itemId);
    if (targetedIndex > -1) {
      filteredItems.splice(targetedIndex, 1);
    }
  }
  store.dispatch('items/refreshItemFilterCount');
}

export function ReplaceOrderline(orderlines: Orderline[], orderlineToReplace: Orderline) {
  let orderlineToRefresh = orderlines.findIndex(i => i.Id === orderlineToReplace.Id);
  if (orderlineToRefresh === -1) {
    orderlines.unshift(orderlineToReplace);
  } else {
    orderlines[orderlineToRefresh] = orderlineToReplace;
  }
}

export function ReplaceOrderlineInStoreAndRefresh(
  store: Store<RootState>,
  orderlines: Orderline[],
  orderlineToReplace: Orderline
) {
  let orderlineToRefresh = orderlines.findIndex(i => i.Id === orderlineToReplace.Id);
  if (orderlineToRefresh === -1) {
    orderlines.unshift(orderlineToReplace);
  } else {
    orderlines[orderlineToRefresh] = new Orderline(orderlineToReplace);
  }
  const newPayload = [...orderlines];
  store.commit('orderlines/orderlinesLoaded', newPayload);
}
export function ReplaceInvoiceInStoreAndRefresh(
  store: Store<RootState>,
  invoices: Invoice[],
  invoiceToReplace: Invoice
) {
  let invoiceToRefreshIndex = invoices.findIndex(i => i.Id === invoiceToReplace.Id);
  if (invoiceToRefreshIndex === -1) {
    invoices.unshift(invoiceToReplace);
  } else {
    invoices[invoiceToRefreshIndex] = invoiceToReplace;
  }
  const newPayload = [...invoices];
  store.commit('invoices/setInvoices', newPayload);
}
