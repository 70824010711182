import { Orderline, OrderlineDetails } from '@/store/models/OrderlineModel';

export const DateHelper = {
  GetDayOfWeek(date: Date) {
    return date.toLocaleDateString('default', { weekday: 'long' });
  },
  GetMonth(date: Date) {
    return date.toLocaleString('default', { month: 'long' });
  },
  GetFormattedDate(dateToChange: Date) {
    let date = new Date(dateToChange);
    let year = date.getFullYear();
    let month = (1 + date.getMonth()).toString().padStart(2, '0');
    let day = date
      .getDate()
      .toString()
      .padStart(2, '0');

    return month + '/' + day + '/' + year;
  },
  ConvertDateYYYYMMDD(date: Date, withDash: boolean = true) {
    var dateString = date
      .toLocaleString('en-us', { year: 'numeric', month: '2-digit', day: '2-digit' })
      .replace(/(\d+)\/(\d+)\/(\d+)/, '$3-$1-$2');
    if (!withDash) dateString = dateString.replace(/-/g, '');
    return dateString;
  },
  GetShipVerbiage(ol: Orderline) {
    switch (ol.OrderStatus.OrderStatusDisplay) {
      case 'Shipped!':
      case 'Delivered':
        return 'Shipped on';
      case 'Pickup Ready!':
        return 'Was ready on';
      case 'Picked up':
        return 'Was picked up on';
      case 'Service Complete!':
        return 'Completed on';
      default: {
        if (ol.IsCustomerPickUp) return 'Pickup will be ready on';
        else {
          // if (ol.IsARush && ol.ShipByDate) {
          //   return 'Should arrive by';
          // }
          return 'Should ship by';
        }
      }
    }
  },
  Debounce(functionToRun: any, timeOutToRefresh: any = null, timeout: number = 500) {
    clearTimeout(timeOutToRefresh);
    timeOutToRefresh = setTimeout(functionToRun, timeout);

    return timeOutToRefresh;
  }
};
