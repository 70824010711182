export const CookieHelpers = {
  getCookie(cname: string) {
    var name = cname + '=';
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  },
  setOptoutOfAlertCookie() {
    let expiration_date = new Date();
    let cookie_string = 'OptoutOfWarning';
    expiration_date.setFullYear(expiration_date.getFullYear() + 1);
    cookie_string =
      'OptoutOfWarning=true; path=/; expires=' + expiration_date.toUTCString();
    document.cookie = cookie_string;
  },
  setGenericCookie(cookieName: string, expirationDate: Date) {
    let cookie_string = `${cookieName}=true; path=/; expires=${expirationDate.toUTCString()}`;
    document.cookie = cookie_string;
  },
  setCookie(cookieName: string, value: any, daysToExpire: number = 30) {
    const date = new Date();
    date.setTime(date.getTime() + daysToExpire * 24 * 60 * 60 * 1000);
    const expires = 'expires=' + date.toUTCString();
    document.cookie = `${cookieName}=${value};${expires};path=/`;
  }
};
