import Vue from 'vue';
import App from './App.vue';
import VueGTag from 'vue-gtag';
import router from './router';
import store from './store/modules/index';
import vuetify from './plugins/vuetify';
import VueKonva from 'vue-konva';
import LottieAnimation from 'lottie-web-vue';
import VueMeta from 'vue-meta';
import './PrototypeExtensions/number.extensions';
import './PrototypeExtensions/boolean.extenstions';
import './PrototypeExtensions/string.extenstions';
import { GlobalFilters } from './filters/GlobalFilters';

import Component from 'vue-class-component';

Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
});

Component.registerHooks(['beforeRouteUpdate', 'metaInfo']);

Vue.config.productionTip = false;

Vue.use(VueKonva);
Vue.use(LottieAnimation);
Vue.use(
  VueGTag,
  {
    config: {
      id: 'G-5LSKM84ZCF',
      params: {
        send_page_view: false
      }
    }
  },
  router
);

Vue.mixin({
  filters: GlobalFilters
});
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app');
