export const ImageHelper = {
  getImageFileNameByStaticItemId(itemId: number) {
    switch (itemId) {
      case 7:
      case 6:
      case 15178:
      case 15154:
      case 21068:
        return 'T1375_150ppi.png';
      case 12:
      case 15641:
      case 15642:
        return 'T004_150ppi.png';
      case 13:
      case 14:
      case 15643:
      case 15644:
        return 'T003_150ppi.png';
      case 20:
      case 21:
      case 16012:
      case 16013:
        return 'T006_150ppi.png';
      case 3:
      case 22:
      case 23:
      case 16024:
      case 16025:
        return 'T007_150ppi.png';
      case 24:
      case 19035:
        return 'T008.png';
      case 25:
      case 19065:
        return 'T009.png';
      case 26:
      case 19066:
        return 'T010.png';
      case 27:
      case 19067:
      case 15187:
        return 'T011.png';
      case 28:
      case 19521:
        return 'FNSKU-Combo.png';
      case 31:
      case 24050:
        return 'T003_Shopping.png';
      case 32:
      case 24051:
        return 'T004_Shopping.png';
      case 33:
      case 24052:
        return 'T006_Shopping.png';
      case 34:
      case 24053:
        return 'T007_Shopping.png';
      case 35:
      case 24054:
      case 24055:
        return 'T1375_Shopping.png';
      case 30:
      case 24043:
        return 'FNSKU-Combo_Shopping.png';
      default: {
        console.log(`no image for static item found for ${itemId}`);
        return '';
      }
    }
  }
};
