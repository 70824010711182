import axios, { AxiosResponse } from 'axios';

export function handleResponse(response: AxiosResponse) {
  if (response.status === 401) {
    localStorage.removeItem('user');
    document.location.reload(true);
  }
}
export function handleErrorResponse(error: any) {
  if (error.response.status === 401) {
    localStorage.removeItem('user');
    document.location.reload(true);
  }
}

export function downloadFile(data: string, fileName: string) {
  console.log(data);
  console.log(fileName);
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  // window.open(url, '_blank');
}

export async function downloadImage(image: HTMLImageElement) {
  const data = await axios.get(image.src);
  // console.log('hello');
  // const imageBlob = await (await axios.get(image.src)).data.blob();
  // const imageURL = URL.createObjectURL(imageBlob);

  // const link = document.createElement('a');
  // link.href = image.src;
  // link.download = 'image.jpg';
  // document.body.appendChild(link);
  // link.click();
  // document.body.removeChild(link);
  return;
}
