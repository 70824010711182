import { Address } from '@/store/models/AddressModel';
import { User } from '@/store/models/ProfileModel';

export const ValidationHelper = {
  getDecimalPlaces(value: number, decimalLimit: number) {
    if (value) {
      let decimals = value.toString().split('.')[1];
      let decimalPlaces = 0;
      if (decimals) {
        decimalPlaces = decimals.length;
      }
      return decimalPlaces <= decimalLimit;
    }
  },
  removeFileExtension(input: string): string {
    if (!input) return '';
    return input.substr(0, input.lastIndexOf('.')) || input;
  },
  isUserAuthenticated(): boolean {
    const c = localStorage.getItem('user')!;
    const user = JSON.parse(c) as User;
    return user && user.isAuthenticated;
  },
  IsAddressValid(address: Address) {
    if (address.IsInternational()) {
      return !!address.Province && !!address.Attn && !!address.Phone;
    }
    return Number.isInteger(Number.parseInt(address.ZipCode)) && !!address.CompanyName;
  }
};
