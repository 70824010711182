import { ConstValues, OrderlinePreflightErrorCodes } from '@/ConstValues';
import { CustomerService } from '@/services/customer-service';
import { OrdersService } from '@/services/orders-service';
import { AddressService } from '@/services/address-service';
import { Address } from '@/store/models/AddressModel';
import { Orderline, OrderlineDetails, Quote } from '@/store/models/OrderlineModel';
import store from '@/store/modules/index';
import { ValidationHelper } from './ValidationHelper';
import { isOrderlineDetails } from './ObjectHelper';
import { ConversionHelper } from './ConversionHelper';

export async function CalculatePrice(
  ols: Array<OrderlineDetails | Orderline>,
  quote: Quote = new Quote()
) {
  let model = ols.map((ol: OrderlineDetails | Orderline) => {
    let orderline;
    if (isOrderlineDetails(ol)) {
      orderline = ConversionHelper.convertOrderlineToAddModel(ol as OrderlineDetails);
    } else {
      orderline = ConversionHelper.convertOrderlineToAddModel(ol as Orderline);
    }
    if (quote.IsQuote) {
      return { ...orderline, ...quote };
    } else {
      return orderline;
    }
  });
  const { data } = await OrdersService.CalculatePricingData(model);
  if (quote.IsQuote) {
    return data;
  }
  return data.map((d: any) => new OrderlineDetails(d));
}
export function HasLikeItems(
  orderlines: OrderlineDetails[],
  orderlineToCheck: OrderlineDetails
) {
  var likeItem = orderlines.find(ol => IsLikeItem(ol, orderlineToCheck));
  return !!likeItem;
}
export function IsLikeItem(ol: OrderlineDetails, olToCheck: OrderlineDetails) {
  return (
    ol.Die.Description == olToCheck.Die.Description &&
    ol.Material.BadgeText == olToCheck.Material.BadgeText &&
    ol.Finish.BadgeText == olToCheck.Finish.BadgeText &&
    ol.Color.ClickColors == olToCheck.Color.ClickColors
  );
}
export async function OrderlinePreflightCheck(
  ol: OrderlineDetails
): Promise<OrderlinePreflightErrorCodes[]> {
  let returnValue = [];
  if (!store.getters['customer/getCustomer'].BillingAddress.Id)
    returnValue.push(OrderlinePreflightErrorCodes.MissingBillingAddress);
  let { data } = await AddressService.GetAddress(ol.Address.Id);
  ol.Address = new Address(data);
  if (!ValidationHelper.IsAddressValid(ol.Address))
    returnValue.push(OrderlinePreflightErrorCodes.ShippingAddressNotComplete);
  return returnValue;
}
